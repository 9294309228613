import React, { useEffect } from "react"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"

import pdfLogo from "../images/delinea-image-whitepaper-pam-maturity-model-thumbnail.jpg"

import PDF from "../media/delinea-whitepaper-pam-maturity-model.pdf"

const PAMEbook = () => {
  //   useEffect(() => {
  //     if (typeof window !== "undefined") {
  //       window.open({ PDF })
  //     }
  //   }, [])
  return (
    <>
      <Layout>
        <SEO
          title="Privileged Access Management Maturity Model"
          description="This step-by-step method for Privileged Access Management adoption helps you build a strong security foundation that supports you as you scale."
          keywords={["", "", ""]}
          lang="en-gb"
        />
        {/*********** Contact *************/}
        <div
          className="contact-section"
          id="contact"
          // style={{ paddingTop: "120px" }}
        >
          <div className="outer-container" style={{ padding: "0" }}>
            {/* <h2>Contact Us</h2> */}
            <div className="pdf-hero cyber-insurance">
              <div className="blur-wrap">
                <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
                  <div style={{ display: "flex" }} className="pdf-hero-wrap">
                    <div
                      style={{
                        display: "flex",
                        // background: "red",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfLogo}
                        alt="delinea image tool cyber insurance checklist thumbnail"
                        style={{ width: "70%" }}
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h1
                        style={{
                          textAlign: "left",
                          color: "#FFFFFF",
                          marginBottom: "0em",
                        }}
                      >
                        Thank you!
                      </h1>
                      <p
                        style={{
                          textAlign: "left",
                          color: "white",
                          fontSize: "x-large",
                        }}
                      >
                        To download you free ebook,
                        <br />
                        <a
                          href={PDF}
                          target="_blank"
                          style={{ color: "#fff", textDecoration: "underline" }}
                        >
                          please click here
                        </a>
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PAMEbook
